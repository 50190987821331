import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faUsers,
  faGlobe,
  faDriversLicense,
  faTruckPickup,
  faMicrochip,
  faBuilding,
  faScroll,
  faNewspaper,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Language } from '../../domain/Language';
import { Locale } from '../../domain/Translation';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import CompanySelect from '../CompanySelect/CompanySelect';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  selectedLocale: Locale;
  languages: Language[];
  currentUser: User | null;
  selectedCompany: number | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
  roles: Roles[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  selectedLocale,
  languages,
  currentUser,
  selectedCompany,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const routeLocation = useLocation();
  const intl = useIntl();

  const { width } = useWindowSize();

  const canSelectCompany = useMemo(() => {
    if (!currentUser) {
      return false;
    }

    return [
      Roles.SUPER_ADMIN,
      Roles.ADMIN,
      Roles.MODERATOR,
      Roles.SUPPORT,
    ].includes(currentUser.role);
  }, [currentUser]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  const GENERAL_ITEMS = {
    label: translate(intl, 'SIDEBAR.GENERAL_SETTINGS'),
    roles: [Roles.SUPER_ADMIN],
    items: [
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_ADMINS'),
        to: routes.admins.list,
        icon: faUsers as IconProp,
        roles: [Roles.SUPER_ADMIN],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
        to: routes.translations,
        icon: faGlobe as IconProp,
        roles: [Roles.SUPER_ADMIN],
      },
    ],
  };

  const COMPANY_SETTINGS = {
    label: translate(intl, 'SIDEBAR.COMPANY_SETTINGS'),
    roles: [
      Roles.SUPER_ADMIN,
      Roles.OWNER,
      Roles.ADMIN,
      Roles.MODERATOR,
      Roles.SUPPORT,
      Roles.ADMIN_USER,
      Roles.USER,
    ],
    items: [
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_DRIVERS'),
        to: routes.drivers.list,
        icon: faDriversLicense as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
          Roles.USER,
        ],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_VEHICLES'),
        to: routes.vehicles.list,
        icon: faTruckPickup as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
          Roles.USER,
        ],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_ELDS'),
        to: routes.elds.list,
        icon: faMicrochip as IconProp,
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANY_USERS'),
        to: routes.companyUsers.list,
        icon: faUsers as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.OWNER,
          Roles.ADMIN_USER,
        ],
      },
    ],
  };

  const LOGS_SETTINGS = {
    label: translate(intl, 'SIDEBAR.LOGS_SETTINGS'),
    roles: [
      Roles.SUPER_ADMIN,
      Roles.ADMIN,
      Roles.MODERATOR,
      Roles.SUPPORT,
      Roles.OWNER,
      Roles.ADMIN_USER,
      Roles.USER,
    ],
    items: [
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_LOGS'),
        to: routes.logs.list,
        icon: faScroll as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
          Roles.USER,
        ],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_UNIDENTIFIED_DRIVING'),
        to: routes.logs.unidentifiedDriving,
        icon: faScroll as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
          Roles.USER,
        ],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_REPORTS'),
        to: routes.reports.list,
        icon: faNewspaper as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
          Roles.USER,
        ],
      },
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_FMCSA_REPORTS'),
        to: routes.fmcsaReports.list,
        icon: faFileSignature as IconProp,
        roles: [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.MODERATOR,
          Roles.SUPPORT,
          Roles.OWNER,
          Roles.ADMIN_USER,
        ],
      },
    ],
  };

  const MANAGEMENT_SETTINGS = {
    label: translate(intl, 'SIDEBAR.MANAGEMENT_SETTINGS'),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR, Roles.SUPPORT],
    items: [
      {
        label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANIES'),
        to: routes.companies.list,
        icon: faBuilding,
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR, Roles.SUPPORT],
      },
    ],
  };

  const getSidebarItems = () => {
    if (!selectedCompany) {
      return [GENERAL_ITEMS];
    }

    return [
      LOGS_SETTINGS,
      COMPANY_SETTINGS,
      MANAGEMENT_SETTINGS,
      GENERAL_ITEMS,
    ];
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    return getSidebarItems()
      .map((sidebarItemGroup) => ({
        label: sidebarItemGroup.label,
        items: sidebarItemGroup.items.filter((sidebarItem) => {
          return (
            !!currentUser?.role && sidebarItem.roles.includes(currentUser.role)
          );
        }),
      }))
      .filter((sidebarItemGroup) => sidebarItemGroup.items.length > 0);
  };

  if (
    routeLocation.pathname.includes(routes.login) ||
    routeLocation.pathname.includes(routes.registrationConfirmation) ||
    routeLocation.pathname.includes(routes.remind) ||
    routeLocation.pathname.includes(routes.resetPassword)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={
              canSelectCompany && (
                <CompanySelect
                  isAuthenticated={isAuthenticated}
                  canSelectCompany={canSelectCompany}
                />
              )
            }
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          <div className={styles.mobileDrawerInner}>
            {canSelectCompany && (
              <CompanySelect
                isAuthenticated={isAuthenticated}
                canSelectCompany={false}
              />
            )}
            {getNavigationGroups().map((navigationGroup) => (
              <div
                className={styles.navigationGroup}
                key={navigationGroup.label}
              >
                <div className={styles.groupName}>{navigationGroup.label}</div>
                {navigationGroup.items.map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  createdUserSetting: state.userSetting.createdUserSetting,
});

export default connect(mapStateToProps)(Layout);
